.App {
  width: 100%;
  overflow-x: hidden;
  min-height: 100vh;
  max-width: 480px;
  margin: 0 auto;
  text-align: center;
}

.note {
  font-size: 2em;
  font-weight: bold;
  background-color: white;
}

.background {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  text-align: center;
  /* background-image: url("images/bg.png"); */
  /* background-repeat: no-repeat; */
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
/*
.storeLogo img {
  width: 200px;
  margin-bottom: 48px;
} */

.bg {
  /* position: absolute; */
  height: 100vh;
  max-width: 480px;
  margin: 0 auto;
  text-align: center;
  /* top: 0;
  left: 0; */
}

.header {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 480px;
  margin: 0 auto;
  text-align: center;
}

.store {
  position: absolute;
  width: 150px;
  bottom: 10%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.logo {
  position: absolute;
  height: 150px;
  width: 150px;
  top: 30%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.download-text {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  color: #007aff;
  font-weight: 700;
  padding: 12px;
  font-size: 22px;
}

.allow-text {
  position: absolute;
  top: 6%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  color: black;
  font-weight: 700;
  padding: 20px 0px;
  font-size: 32px;
  border-bottom: 3px solid black;
  max-width: 480px;
  margin: 0 auto;
  text-align: center;
}

.download-container {
  background-color: black;
  border-radius: 50px;
  height: 42px;
  width: 80%;
  bottom: 30%;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.store-text {
  color: white;
  font-weight: bolder;
  margin: 0;
}

.inner-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.apple-logo {
  width: 24px;
  margin-right: 8px;
}

.android-logo {
  width: 18px;
  margin-right: 8px;
}
